import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalVariables } from 'src/app/common/global-variables';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  form: FormGroup;
  name = '';
  catId = '';
  alphabet:string;
  categoryLists: [];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    public globalVar: GlobalVariables,
    public datePipe: DatePipe,
    private formBuilder: FormBuilder

  ) { }

  ngOnInit() {
    //query data get
    this.route.queryParams.subscribe(params => {

    this.name = (params['name'])?params['name']:'';
    this.catId = (params['cat_id'])?params['cat_id']:'';

    //Form Controls
    this.form = this.formBuilder.group({
      name: [this.name],
      cat_id: [this.catId],
    })

     //cetegorty lists
     this.getCategoryLists();
    }); 
    
  }

  get f() { 
    return this.form.controls; 
  }
  //Submit Form
  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.name = this.f.name.value;
    this.catId = this.f.cat_id.value;

    let url = '';
    if (this.catId && this.name) {
      url = 'lists?name=' + this.name + '&cat_id=' + this.catId;
    } else if (this.catId) {
      url = 'lists?cat_id=' + this.catId;
    } else if (this.name) {
      url = 'lists?name=' + this.name;
    } else {
      url = 'lists'; 
    }
    //Redirect user to Dashboard
    window.location.href = url;
  }

  onchangecategory(){

    this.name = this.f.name.value;
    this.catId = this.f.cat_id.value;

    let url='';
    if(this.catId && this.name){
      url = 'lists?name='+this.name+'&cat_id='+this.catId;
    }else if(this.catId){
      url = 'lists?cat_id='+this.catId;
    }else if(this.name){
      url = 'lists?name='+this.name;
    }else{
      url = 'lists'; 
    }
    //Redirect user to Dashboard
    window.location.href = url;    
  }

  getCategoryLists() {
    this.apiService.getCategoryListsApi()
      .then(responseData => {
        if ((responseData as any).response == 'success') {
          this.categoryLists = (responseData as any).data;
        }
      }).catch(e => {
        console.log('error - ' + e.message);
      });
  }

}
