<!--################### Celebrity Listing Start #######################-->

<!-- Fiter Section Start -->
<section class="filter__section">
  <div class="container">
    <div class="row">
      <div class="col-12">
      
          <div class="filter__heading">
            <div class="row">
              <div class="col-md-12">
                <h2>Celebrity Horoscopes</h2>
              </div>
            </div>
          </div>
          <div class="filter__by_alphabet">
            <h6>Browse Celebrity</h6>
            <ul>
              <li><a href="lists?alphabet=a">A</a></li>
              <li><a href="lists?alphabet=b">B</a></li>
              <li><a href="lists?alphabet=c">C</a></li>
              <li><a href="lists?alphabet=d">D</a></li>
              <li><a href="lists?alphabet=e">E</a></li>
              <li><a href="lists?alphabet=f">F</a></li>
              <li><a href="lists?alphabet=g">G</a></li>
              <li><a href="lists?alphabet=h">H</a></li>
              <li><a href="lists?alphabet=i">I</a></li>
              <li><a href="lists?alphabet=j">J</a></li>
              <li><a href="lists?alphabet=k">K</a></li>
              <li><a href="lists?alphabet=l">L</a></li>
              <li><a href="lists?alphabet=m">M</a></li>
              <li><a href="lists?alphabet=n">N</a></li>
              <li><a href="lists?alphabet=o">O</a></li>
              <li><a href="lists?alphabet=p">P</a></li>
              <li><a href="lists?alphabet=q">Q</a></li>
              <li><a href="lists?alphabet=r">R</a></li>
              <li><a href="lists?alphabet=s">S</a></li>
              <li><a href="lists?alphabet=t">T</a></li>
              <li><a href="lists?alphabet=u">U</a></li>
              <li><a href="lists?alphabet=v">V</a></li>
              <li><a href="lists?alphabet=w">W</a></li>
              <li><a href="lists?alphabet=x">X</a></li>
              <li><a href="lists?alphabet=y">Y</a></li>
              <li><a href="lists?alphabet=z">Z</a></li>
            </ul>
          </div>
      </div>
    </div>
  </div>
</section>
<!-- Fiter Section End -->

<!-- Listing Section Start -->
<section class="celebrity__listing__section">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <!-- Listing -->
        <div class="listing__heading">
          <div class="row">
            <div class="col-sm-8">
              <h2>Recently Added</h2>
            </div>
            <div class="col-sm-4"> <a href="lists"
                 class="btn btn-red-1">View All</a> </div>
          </div>
        </div>
        <!-- Listing -->
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
           *ngFor="let item of this.celebrityLatest">
        <div class="listing__itembox">
          <div class="listing__itembox_pic"
               *ngIf="item.image"> <a href="{{item.sitemap_url}}"><img [src]="globalVar.rootUrl+item.image" /></a>
          </div>
          <div class="listing__itembox_content">
            <h3> <a href="{{item.sitemap_url}}">{{item.name}}</a></h3>
            <div class="description"
                 *ngIf="item.about_celebrity"
                 [innerHTML]="item.about_celebrity.substr(0, 70)">

            </div>

            <a href="{{item.sitemap_url}}"
               class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Listing Section Start -->
<section class="celebrity__listing__section">
  <div class="container">
    <div class="row">
      <div class="col-12">

        <!-- Listing -->
        <div class="listing__heading">
          <div class="row">
            <div class="col-sm-8">
              <h2>Most Visited</h2>
            </div>
            <div class="col-sm-4"> <a href="lists"
                 class="btn btn-red-1">View All</a> </div>
          </div>
        </div>
        <!-- Listing -->
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
           *ngFor="let item of this.celebrityMostVisited">
        <div class="listing__itembox">
          <div class="listing__itembox_pic"
               *ngIf="item.image"> <a href="{{item.sitemap_url}}"><img [src]="globalVar.rootUrl+item.image" /></a>
          </div>
          <div class="listing__itembox_content">
            <h3> <a href="{{item.sitemap_url}}">{{item.name}}</a></h3>
            <div class="description"
                 *ngIf="item.about_celebrity"
                 [innerHTML]="item.about_celebrity.substr(0, 70)">

            </div>

            <a href="{{item.sitemap_url}}"
               class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="celebrity__listing__section"  *ngFor="let celebrityitem of this.celebrityCategoryVise">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- Listing -->
        <div class="listing__heading">
          <div class="row">
            <div class="col-sm-8">
              <h2>{{celebrityitem.title}}</h2>
            </div>
            <div class="col-sm-4"> <a href="{{'lists?cat_id='+celebrityitem.id}}"
                 class="btn btn-red-1">View All</a> </div>
          </div>
        </div>
        <!-- Listing -->
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
           *ngFor="let item of this.celebrityitem.celebrity_web_record">
        <div class="listing__itembox">
          <div class="listing__itembox_pic"
               *ngIf="item.image"> <a href="{{item.sitemap_url}}"><img [src]="globalVar.rootUrl+item.image" /></a>
          </div>
          <div class="listing__itembox_content">
            <h3> <a href="{{item.sitemap_url}}">{{item.name}}</a></h3>
            <div class="description"
                 *ngIf="item.about_celebrity"
                 [innerHTML]="item.about_celebrity.substr(0, 70)">

            </div>

            <a href="{{item.sitemap_url}}"
               class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Listing Section End -->

<!--################### Celebrity Listing End #######################-->