<section>
    <div class="blk-strip">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-7">
            <h2>Best Techniques For Great Predictions</h2>
            <p>Download Vedic Astrology App. from Google Play store. It has many of Saptarishis Astrology's
              techniques which are included for the first time in any software.</p>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-6  col-xl-5 button-con ">
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.saptarishis&hl=en_IN" title=""
              class="btn btn-border"><i class="fab fa-google-play"></i> Download App</a>
            <a href='https://saptarishis.net/contact' title="" class="btn btn-white">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-3 order-1">
          <ul>
            <li><a href='{{globalVar.homeUrl}}' title="Home">Home</a></li>
            <li><a href='{{globalVar.rootUrl}}about' title="About">About</a></li>
            <li><a href="https://www.saptarishisshop.com/" title="Shop" target="_blank">Saptarishis Book Shop</a></li>
            <li><a href='{{globalVar.rootUrl}}contact' title="Contact">Contact</a></li>
          </ul>
        </div>
        <div class="col-12 col-sm-12 col-md-6 text-center order-2">
          <div class="copy">
            <h2>Follow Us</h2>
            <div class="social">
              <ul>
                <li><a href="https://www.facebook.com/AstrologersDesk" target="_blank"><i class="fab fa-facebook-square"></i></a></li>
                <!-- <li><a href="" target="_blank"><i class="fab fa-twitter-square"></i></a></li> -->
                <li><a href="https://www.youtube.com/c/SaptarishisAstrology108" target="_blank"><i class="fab fa-youtube"></i></a></li>
                 <li><a href="https://www.instagram.com/astrologersdesk/" target="_blank"><i class="fab fa-instagram"></i></a></li>
              </ul>
            </div>
  
            <p>&copy; 2021 Saptarishis Software, LLC. All rights reserved.</p>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 text-right order-3">
          <ul>
            <li><a href='{{globalVar.rootUrl}}privacy-policy' title="Privacy Policy">Privacy Policy</a></li>
            <li><a href='{{globalVar.rootUrl}}terms-and-conditions' title="Terms &amp; Conditions">Terms &amp;
                Conditions</a></li>
            <li><a href='{{globalVar.rootUrl}}disclaimer' title="Disclaimer">Disclaimer</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
  