import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalVariables } from 'src/app/common/global-variables';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  celebrityLatest: [];
  celebrityMostVisited: [];
  celebrityCategoryVise: [];
  categoryLists: [];
  name = '';
  catId = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public globalVar: GlobalVariables,
    public datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    //cetegorty lists
    this.getCategoryLists();
    // celebrity lists
    this.getcelebrityLists();
  }

  getcelebrityLists() {
    this.apiService.getCelebrityWithCategoryApi()
      .then(responseData => {

        if ((responseData as any).response == 'success') {
          this.celebrityLatest = (responseData as any).data;
          this.celebrityMostVisited = (responseData as any).most_visited;
          this.celebrityCategoryVise = (responseData as any).cat_result;
        }
      }).catch(e => {
        console.log('error - ' + e.message);
      });
  }

  getCategoryLists() {
    this.apiService.getCategoryListsApi()
      .then(responseData => {
        if ((responseData as any).response == 'success') {
          this.categoryLists = (responseData as any).data;
        }
      }).catch(e => {
        console.log('error - ' + e.message);
      });

  }

}
