import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { GlobalVariables } from 'src/app/common/global-variables';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = this.globalVar.apiUrl;

  constructor(
    public http: HttpClient,
    public globalVar: GlobalVariables
  ) { }

  // celebrity data
  getCelebrityDetailsApi(name) {

    const bodyParam = new FormData();
    bodyParam.append('name', name);

    return new Promise(resolve => {
      this.http.post(this.apiUrl + '/getcelebritydetails', bodyParam).subscribe(data => {
        resolve(data);
      },
        err => {
          console.log(err);
        });
    });
  }

  // celebrity data
  getCelebrityListsApi(alphabet, name, categoryId,page) {

    const bodyParam = new FormData();
    bodyParam.append('alphabet', alphabet);
    bodyParam.append('name', name);
    bodyParam.append('category_id', categoryId);
    bodyParam.append('page', page);


    return new Promise(resolve => {
      this.http.post(this.apiUrl + '/getcelebritywebfilter', bodyParam).subscribe(data => {
        resolve(data);
      },
        err => {
          console.log(err);
        });
    });
  }
   // celebrity data
   getCategoryListsApi() {   

    return new Promise(resolve => {
      this.http.post(this.apiUrl + '/getcelebritywebcategory','').subscribe(data => {
        resolve(data);
      },
        err => {
          console.log(err);
        });
    });
  }
   // celebrity data
   getCelebrityWithCategoryApi() {   

    return new Promise(resolve => {
      this.http.post(this.apiUrl + '/getcelebrityhomepage','').subscribe(data => {
        resolve(data);
      },
        err => {
          console.log(err);
        });
    });
  }
}
