<header class="header-outer-main">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-12">

        <nav class="navbar navbar-expand-lg navbar-dark ">

          <a class="navbar-brand" href='{{globalVar.rootUrl}}'>
            <img src="/assets/images/admin_logo_new.png" alt="logo" />
          </a>

          <form [formGroup]="form" action="/lists" method="GET" (ngSubmit)="onSubmit()" class="input-group header-search">

          <div class="input-group header-search" style="margin:0">
            <div class="input-group-prepend minwid">
              <select formControlName="cat_id">
                <option value="">All Celebrities</option>
                <option *ngFor="let item of this.categoryLists" value="{{item.id}}" >{{item.title}}</option>
              </select>
            </div>
            <input formControlName="name" type="text" placeholder="Search Indian Celebrity By Name" class="form-control search">
            <div class="input-group-append">
              <button class="btn btn-red" (click)="onchangecategory()" type="button"><i class="fas fa-search"></i></button>
            </div>
          </div>
        </form>

          <button _ngcontent-iub-c99="" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            class="navbar-toggler collapsed"><span _ngcontent-iub-c99="" class="navbar-toggler-icon"></span></button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item"><a class="nav-link" href='{{globalVar.homeUrl}}' >Home</a></li>
              <li class="nav-item"><a class="nav-link" href='{{globalVar.rootUrl}}about'>About</a></li>
              <li class="nav-item"><a class="nav-link" href="https://www.saptarishisshop.com/" target="_blank">Shop</a>
              </li>
              <li class="nav-item"><a class="nav-link" href='{{globalVar.rootUrl}}contact'>Contact</a></li>
              <li class="nav-item"><a class="nav-link" href='{{globalVar.rootUrl}}report'>Reports</a></li>

            </ul>

          </div>
        </nav>
      </div>
    </div>
  </div>
</header>