<div class="celebrity_outer" *ngIf="this.celebrityDetailData">
<section class="clebs-details">
    <div class="container" >
        <div class="row">
            <div class="col-md-5" *ngIf="this.celebrityDetailData.image">
                <div class="images-celeb">
                    <img [src]="globalVar.rootUrl+this.celebrityDetailData.image" alt="img">
                </div>
                <!-- <div class="information-source">
                    <div class="details-title">
                        <h3>Image Credit:</h3>
                    </div>
                    <span class="detail-main"> {{ this.celebrityDetailData.image_credit }}</span>
                </div>
                <div class="information-source btnspecing">
                    <div class="details-title ">
                        <h3>Birth Detail Source:</h3>
                    </div>
                    <span class="detail-main"> {{ this.celebrityDetailData.birth_detail_source }}</span>
                </div> -->
            </div>
            <div class="col-md-7">
                <div class="title-part-celeb">
                    <h2>{{ this.celebrityDetailData.name }}'s Horoscope</h2>
                </div>

                <div class="details-part-celeb">
                    <ul>
                        <li>
                            <div class="details-title">
                                <h3>Name: </h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.name }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Date of Birth:</h3>
                            </div>
                            <span class="detail-main">
                                {{this.datePipe.transform(this.celebrityDetailData.date_of_birth, 'MMM d, y')}}
                            </span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Time of Birth:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.birth_time }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Place of Birth:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.birth_place }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Longitude:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.longitude }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Latitude:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.latitude }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Time Zone:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.timezone }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Image Credit:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.image_credit }}</span>
                        </li>
                        <li>
                            <div class="details-title">
                                <h3>Birth Detail Source:</h3>
                            </div>
                            <span class="detail-main"> {{ this.celebrityDetailData.birth_detail_source }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-clebs">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-about-celeb">
                    <h2>About {{ this.celebrityDetailData.name }}</h2>                    
                    <div class="description" [innerHTML]="this.celebrityDetailData.about_celebrity"></div>                   
                </div>
            </div>

        </div>
    </div>
</section>

<section class="clebs-chart-section" *ngIf="this.celebrityDetailData.chart_image">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="cleb-chart">
                    <h2>Birth Chart</h2>
                    <img [src]="globalVar.rootUrl+this.celebrityDetailData.chart_image" alt="img">
                </div>
            </div>
        </div>
    </div>
</section>


<section class="about-clebs">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title-about-celeb">
                    <h2> {{ this.celebrityDetailData.name }}'s Life Events</h2>                    
                    <div class="description" [innerHTML]="this.celebrityDetailData.events"></div>
                </div>

            </div>

        </div>
    </div>
</section>
</div>



