import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { GlobalVariables } from 'src/app/common/global-variables';
import { DatePipe } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-celebrity-detail',
  templateUrl: './celebrity-detail.component.html',
  styleUrls: ['./celebrity-detail.component.css']
})
export class CelebrityDetailComponent implements OnInit {

  currentUrl:string;
  pageSlug:string;
  celebrityDetailData:any;

  constructor(    
     private route: ActivatedRoute,
     private router: Router,
     private apiService: ApiService,
     public globalVar: GlobalVariables,
     public datePipe: DatePipe,
     private titleService: Title, 
     private metaService: Meta

    ) { }

  ngOnInit(): void {

    this.currentUrl = this.router.url;
    let reportNameUrl = '';

    reportNameUrl = this.currentUrl.substring(this.currentUrl.lastIndexOf("/") + 1, this.currentUrl.length);

    this.pageSlug = reportNameUrl;
    this.getCelebrityDetails(reportNameUrl);

  }

  getCelebrityDetails(name){

    this.apiService.getCelebrityDetailsApi(name)
      .then(responseData => {
        if ((responseData as any).response == 'success') {         
              this.celebrityDetailData = (responseData as any).data;
              this.updateMetasTags(this.celebrityDetailData);
            }         
      }).catch(e => {
        console.log('error - ' + e.message);
      });
      
  }
  updateMetasTags(celebritydata){

    this.titleService.setTitle(celebritydata.name);

    this.metaService.addTag(
      {name: 'description', content: celebritydata.description}
    );

  }

}
