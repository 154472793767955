import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CelebrityDetailComponent } from './celebrity-detail/celebrity-detail.component';
import { CelebrityListsComponent } from './celebrity-lists/celebrity-lists.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'lists',
    component: CelebrityListsComponent
  },
  {
    path: ':celebrityname',
    component: CelebrityDetailComponent
  } 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
