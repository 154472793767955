<!--################### Celebrity Listing Start #######################-->

<!-- Fiter Section Start -->
<section class="filter__section">
  <div class="container">
    <div class="row">
      <div class="col-12">
          <div class="filter__heading">
            <div class="row">
              <div class="col-md-12">
                <h2>Celebrity Horoscopes</h2>
              </div>

            </div>
          </div>
          <div class="filter__by_alphabet">
            <h6>Browse Celebrity</h6>
            <ul>
              <li [ngClass]="{'active': this.alphabet == 'a'}"><a href="lists?alphabet=a">A</a></li>
              <li [ngClass]="{'active': this.alphabet == 'b'}"><a href="lists?alphabet=b">B</a></li>
              <li [ngClass]="{'active': this.alphabet == 'c'}"><a href="lists?alphabet=c">C</a></li>
              <li [ngClass]="{'active': this.alphabet == 'd'}"><a href="lists?alphabet=d">D</a></li>
              <li [ngClass]="{'active': this.alphabet == 'e'}"><a href="lists?alphabet=e">E</a></li>
              <li [ngClass]="{'active': this.alphabet == 'f'}"><a href="lists?alphabet=f">F</a></li>
              <li [ngClass]="{'active': this.alphabet == 'g'}"><a href="lists?alphabet=g">G</a></li>
              <li [ngClass]="{'active': this.alphabet == 'h'}"><a href="lists?alphabet=h">H</a></li>
              <li [ngClass]="{'active': this.alphabet == 'i'}"><a href="lists?alphabet=i">I</a></li>
              <li [ngClass]="{'active': this.alphabet == 'J'}"><a href="lists?alphabet=j">J</a></li>
              <li [ngClass]="{'active': this.alphabet == 'k'}"><a href="lists?alphabet=k">K</a></li>
              <li [ngClass]="{'active': this.alphabet == 'l'}"><a href="lists?alphabet=l">L</a></li>
              <li [ngClass]="{'active': this.alphabet == 'm'}"><a href="lists?alphabet=m">M</a></li>
              <li [ngClass]="{'active': this.alphabet == 'n'}"><a href="lists?alphabet=n">N</a></li>
              <li [ngClass]="{'active': this.alphabet == 'o'}"><a href="lists?alphabet=o">O</a></li>
              <li [ngClass]="{'active': this.alphabet == 'p'}"><a href="lists?alphabet=p">P</a></li>
              <li [ngClass]="{'active': this.alphabet == 'q'}"><a href="lists?alphabet=q">Q</a></li>
              <li [ngClass]="{'active': this.alphabet == 'r'}"><a href="lists?alphabet=r">R</a></li>
              <li [ngClass]="{'active': this.alphabet == 's'}"><a href="lists?alphabet=s">S</a></li>
              <li [ngClass]="{'active': this.alphabet == 't'}"><a href="lists?alphabet=t">T</a></li>
              <li [ngClass]="{'active': this.alphabet == 'u'}"><a href="lists?alphabet=u">U</a></li>
              <li [ngClass]="{'active': this.alphabet == 'v'}"><a href="lists?alphabet=v">V</a></li>
              <li [ngClass]="{'active': this.alphabet == 'w'}"><a href="lists?alphabet=w">W</a></li>
              <li [ngClass]="{'active': this.alphabet == 'x'}"><a href="lists?alphabet=x">X</a></li>
              <li [ngClass]="{'active': this.alphabet == 'y'}"><a href="lists?alphabet=y">Y</a></li>
              <li [ngClass]="{'active': this.alphabet == 'z'}"><a href="lists?alphabet=z">Z</a></li>
            </ul>
          </div>
      </div>
    </div>
  </div>
</section>
<!-- Fiter Section End -->

<!-- Listing Section Start -->
<section class="celebrity__listing__section page-section">
  <div class="container">
    <div class="row" *ngIf="lastArrCelebrities.length > 0">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3" *ngFor="let item of this.lastArrCelebrities">
        <div class="listing__itembox">
          <div class="listing__itembox_pic" *ngIf="item.image"> <a href="{{item.sitemap_url}}"><img
                [src]="globalVar.rootUrl+item.image" /></a> </div>
            <div class="listing__itembox_content">
                <h3> <a href="{{item.sitemap_url}}">{{item.name}}</a></h3>
                <div class="description" *ngIf="item.about_celebrity" [innerHTML]="item.about_celebrity.substr(0, 70)">
            </div>

            <a href="{{item.sitemap_url}}" class="read-more">Read More</a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="checklen == 2">
      <div class="col-12">
        <p>No Results Found.</p>
      </div>
    </div>

  </div>
</section>
<!-- Listing Section End -->

<!--################### Celebrity Listing End #######################-->